import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const Category = styled.div`
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 600;

  > a {
    display: inline-block;
    margin-right: 10px;
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const Meta = styled.time`
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace !important;
  font-size: 12px;
  color: var(--gray);
`

export const Lead = styled.p`
  margin: 0 auto 20px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
`

export const CoverImg = styled.img`
  display: block;
  margin: 40px auto;
  width: 100%;
`

export const Content = styled.div`
  p {
    margin: 0 0 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
  }
`
