import React from 'react'
import PropTypes from 'prop-types'

import Link from '@atoms/Link/index.js'

import { Wrapper, Category, Meta, Lead, CoverImg, Content } from './styles.js'

const BlogArticle = ({ data }) => {
  let contentText = data.content.value.document.children
    .map(block => block.type === 'paragraph' && block.children[0].value)
    .toString()

  function readingTime(content) {
    const wpm = 180
    const words = content.trim().split(/\s+/).length + 50
    const time = Math.ceil(words / wpm)
    return time
  }

  return (
    <Wrapper>
      <Lead>{data.lead}</Lead>
      <Category>
        {data.category.map(category => (
          <Link to={`blog/category/${category.slug}`} title={category.name}>
            {category.name}
          </Link>
        ))}
      </Category>
      <Meta>
        {data.meta.publishedAt} | {readingTime(contentText)} mins read
      </Meta>
      <CoverImg src={data.cover.url} alt={data.cover.alt || data.title} />
      <Content>
        {data.content.value.document.children.map(block => {
          let blockComponent

          block.type === 'paragraph' &&
            (blockComponent = <p key={block.id}>{block.children[0].value}</p>)

          return blockComponent
        })}
      </Content>
    </Wrapper>
  )
}

BlogArticle.propTypes = {
  data: PropTypes.object,
}

BlogArticle.defaultProps = {
  data: null,
}

export default BlogArticle
