import React from 'react'
import PropTypes from 'prop-types'

import Container from '@atoms/Container'

import { Wrapper, Content } from './styles'

const SectionWhite = ({ children }) => {
  return (
    <Wrapper>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  )
}

SectionWhite.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default SectionWhite
