import styled from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1000;
  overflow: hidden;
  position: relative;
  background: var(--bg-white-color);
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5rem 0;
`
