import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'

import TextBox from '@atoms/TextBox'
import HorWrap from '@atoms/HorWrap'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import BlogArticle from '@molecules/BlogArticle'

import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const BlogPostPage = ({ data, transitionStatus }) => {
  const postData = data.blogPost
  return (
    <Main seo={postData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title={postData.category[0].name} center>
          <h2>{postData.title}</h2>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <HorWrap>
          <BlogArticle data={postData} />
        </HorWrap>
        {/*  BLOG CTA SECTION */}
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query BlogPostBySlug($id: String) {
    blogPost: datoCmsBlogPost(id: { eq: $id }) {
      id
      slug
      seoMetaTags {
        tags
      }
      meta {
        publishedAt(formatString: "DD/MM/YY")
        status
      }
      category {
        name
        slug
      }
      cover {
        alt
        url
      }
      title
      lead
      content {
        blocks
        links
        value
      }
    }
  }
`

export default BlogPostPage
